
import { Component, Vue, Watch, Prop, } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ConfirmRejectDialog extends Vue {
  @Prop({ required: true, }) readonly showDialog!: boolean
  private justification = ''

  private dialog = false

  @Watch('showDialog')
  show (val: boolean): void {
    if (val) {
      this.dialog = true
    }
  }

  @Watch('dialog')
  dialogClose (): void {
    if (this.dialog === false) {
      this.$mixpanel.track('Cerrar - modal correciones Step 3')
      this.$emit('close')
    }
  }

  private sendJustification () {
    if (this.justification.trim().length === 0) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Debe rellenar el campo de justificación',
      })
      this.justification = ''      
      return
    }

    this.dialog = false
    this.$emit('action', this.justification)
  }
}
