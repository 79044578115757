
import { Component, Vue, Watch, Prop, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'

@Component({
  components: {},
  computed: {
    ...mapGetters('ActivitiesModule', { selectedActivity: 'getSelectedActivity', }),
  },
})
export default class ConfirmApproveDialog extends Vue {
  @Prop({ required: true, }) readonly showDialog!: boolean
  public selectedActivity: any

  private justification = ''

  private dialog = false

  @Watch('showDialog')
  show (val: boolean): void {
    if (val) {
      this.dialog = true
    }
  }

  @Watch('dialog')
  dialogClose (): void {
    if (this.dialog === false) {
      this.$mixpanel.track('Cerrar - modal ir a documentos Step 5')
      this.$emit('close')
    }
  }

  private goToMaintenance () {
    this.$mixpanel.track('Ir a documentos', {'Actividad': this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,})
    this.$router.push({
      name: 'maintenanceDetail',
      params: { activity_id: this.$route.params.task_id, },
    })
  }
}
