
import { Component, Vue, Watch, Prop, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {OverlayBackground},
  computed: {
    ...mapGetters('ActivitiesModule', { selectedActivity: 'getSelectedActivity', }),
  },
})
export default class ConfirmReviewRequest extends Vue {
  @Prop({ required: true, }) readonly reviewDialog!: boolean
  public selectedActivity: any
  private loading = false;
  private reviewShowDialog = false

  @Watch('reviewDialog')
  show (val: boolean): void {
    if (val) {
      this.reviewShowDialog = true
    }
  }

  @Watch('reviewShowDialog')
  dialogClose (): void {
    if (this.reviewShowDialog === false) {
      this.$mixpanel.track('Cerrar - Modal nueva solicitud de revision - Step 5')
      this.$emit('close')
    }
  }

  private requestReview () {
    this.loading = true
    this.reviewShowDialog = false
    this.$mixpanel.track('Nueva solicitud de revision - Step 5', {'Actividad': this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,})
    this.$store.dispatch('SteppersModule/requestReview', this.$route.params.task_id).then(() => {
      this.loading = false
      this.$emit('refresh')
      this.$emit('close')
    })
  }
}
