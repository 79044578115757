
import { Vue, Component } from "vue-property-decorator";
import NavigationImplementation from "@/components/V2/NavigationImplementation/Index.vue";
import SideNavImplementationComponent from "@/components/V2/SideNavImplementation/Index.vue";
import CommentAreaComponent from "@/components/CommentArea/Index.vue";
import CommentBoxComponent from "@/components/CommentBox/Index.vue";
import StepContainer from "./Components/SteperContainer.vue";
import { mapGetters } from "vuex";
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    NavigationImplementation,
    SideNavImplementationComponent,
    CommentAreaComponent,
    CommentBoxComponent,
    StepContainer,OverlayBackground
  },
  computed: {
    ...mapGetters("ActivitiesModule", [
      "getSelectedActivity",
      "ActivitiesByFrimework",
      "getComments",
    ]),
    ...mapGetters("ActivitiesModule", { selectedActivity: "getSelectedActivity" }),
  },
})
export default class ImplementationDetailComponent extends Vue {
  public openComments = true;
  public selectedActivity: any;
  public responseData = [];
  private role = this.$store.state.AuthModule.AuthState.role;
  private loading = false;

  mounted() {
    this.getActivityDetail();
    this.getSteppersList();
    this.getActivityComments();
  }

  private getActivityComments() {
    this.$store.dispatch(
      "ActivitiesModule/getActivitiesComments",
      this.$route.params.task_id
    );
  }

  private getSteppersList() {
    this.$store.dispatch(
      "SteppersModule/getSteppersActivity",
      this.$route.params.task_id
    );
  }

  private getActivityDetail() {
    this.loading = true;
    this.$store
      .dispatch("ActivitiesModule/getActivityById", this.$route.params.task_id)
      .then((response) => {
        this.responseData = response;
        this.getActivityComments();
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        if (this.role.includes("Implementador")) {
          this.$router.push({ name: "clientPlan" });
        } else {
          this.$router.push({ name: "activity" });
        }
      });
  }

  private sendMessage(message: string) {
    this.loading = true;
    this.$store
      .dispatch("ActivitiesModule/postActivitiesComment", {
        act_id: this.$route.params.task_id,
        message,
      })
      .then(() => {
        this.loading = false;
        this.getActivityComments();
      })
      .catch(() => {
        this.loading = false;
        this.getActivityComments();
      });
  }
}
