
import { Vue, Component, Prop, } from 'vue-property-decorator'
import UploadFile from './UploadFile.vue'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'
import DoneActivityDialog from './DoneActivityDialog.vue'
import FinishDialog from './FinishDialog.vue'
import SelectFrecuency from '@/components/SelectFrecuency/Index.vue'
import { SelectInterface, Frecuency, } from '@/utils/Select'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    UploadFile,
    DoneActivityDialog,
    FinishDialog,
    SelectFrecuency,OverlayBackground
  },
  computed: {
    ...mapGetters('SteppersModule', ['getDocumentsStepper',]),
    ...mapGetters('SteppersModule', { documentsStepper: 'getDocumentsStepper', }),
    ...mapGetters('DocumentModule', { periodicity: 'checkEveryFrecuency', }),
  },
})
export default class StepSix extends Vue {
  @Prop({ required: false, }) readonly steperLenght!: number
  private documentsStepper: any
  private loading = false;

  private date = ''
  private fileToUpload: FileUploadInterface[] = []
  private showDialogDone = false
  private showDialogFinish = false
  private new_file = false

  private frecuency_value: SelectInterface[] = Frecuency
  private frecuency_selected = ''
  private periodicity: any
  private isDone = false

  mounted () {
    this.getFilesDocuments()
  }

  private selectedFrecuency (element: any) {
    this.frecuency_selected = element
  }

  private openModalFinish () {
    this.saveFinalDocument()
  }

  private goToDocuments () {
    this.$router.push({ name: 'documents', })
  }

  get getButtonName () {
    if (this.steperLenght === 5) {
      this.isDone = true
      return 'Terminar actividad'
    } else {
      this.isDone = false
      return 'Continuar'
    }
  }

  private refreshDocuments (template: FileUploadInterface[]) {
    this.fileToUpload = template
  }

  private refreshAPI () {
    this.getFilesDocuments()
  }

  private newFile (template: any) {
    this.new_file = template
  }

  private finish () {
    this.showDialogFinish = true
  }

  private nextStep () {
    if (this.steperLenght === 5) {
      this.showDialogDone = true
    } else {
      this.saveFinalDocument()
    }
  }

  private saveFinalDocument () {
    this.loading = true

    const data = {
      new_file: this.documentsStepper ? false : true,
      act_id: this.$route.params.task_id,
      datarooms_data: this.fileToUpload,
      periodicity: this.frecuency_selected === '' ? 'one_year' : this.frecuency_selected,
    }
    if (this.getButtonName == 'Continuar') {
      this.$mixpanel.track('Continuar - Step 6', {
        Actividad: this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
      })
    }
    this.$store.dispatch('SteppersModule/saveFinalDocument', data).then(() => {
      this.loading = false

      if (this.isDone) {
        this.$store
          .dispatch('SteppersModule/finishedSummary', this.$route.params.task_id)
          .then(() => {
            this.$store.commit('SteppersModule/SET_OPEN_MODAL', true)
          })
      }

      this.$emit('refresh')
    })
  }

  private requestBack () {
    if (this.getButtonName == 'Continuar') {
      this.$mixpanel.track('Volver atras - Step 6', {
        Actividad: this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
      })
    } else {
      this.$mixpanel.track('Volver atras - Step 5', {
        Actividad: this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
      })
    }

    this.loading = true
    this.$store.dispatch('SteppersModule/requestBack', this.$route.params.task_id).then(() => {
      this.loading = false
      this.$emit('refresh')
    })
  }

  private getFilesDocuments () {
    this.$store.dispatch('SteppersModule/getFilesDocuments', this.$route.params.task_id)
  }
}
