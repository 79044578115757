
import { Component, Vue, Watch, Prop, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'

@Component({
  components: {},
  computed: {
    ...mapGetters('BusinessModule', ['getSelectedProduct',]),
  },
})
export default class ConfirmApproveDialog extends Vue {
  @Prop({ required: true, }) readonly showDialog!: boolean
  private justification = ''

  private dialog = false

  @Watch('showDialog')
  show (val: boolean): void {
    if (val) {
      this.dialog = true
    }
  }

  @Watch('dialog')
  dialogClose (): void {
    if (this.dialog === false) {
      this.$mixpanel.track('Cerrar - modal aprobacion Step 3')
      this.$emit('close')
    }
  }

  private sendJustification () {
    this.dialog = false
    this.$emit('action')
  }
}
