
import { Vue, Component, Watch, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'

// Steps
import FinishDialog from './FinishDialog.vue'

import StepOne from './StepOne.vue'
import StepTwo from './StepTwo.vue'
import StepThree from './StepThree.vue'
import StepFour from './StepFour.vue'
import StepFive from './StepFive.vue'
import StepSix from './StepSix.vue'
import StepSeven from './StepSeven.vue'

@Component({
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    StepSeven,
    FinishDialog,
  },
  computed: {
    ...mapGetters('ActivitiesModule', ['getSelectedActivity', 'getComments',]),
    ...mapGetters('SteppersModule', ['getSteppers', 'getStepperStay',]),
    ...mapGetters('ActivitiesModule', { selectedActivity: 'getSelectedActivity', }),
  },
})
export default class ImplementationDetailComponent extends Vue {
  private selectedActivity: any
  public openComments = true
  public doneActivity = false
  private role = this.$store.state.AuthModule.AuthState.role
  private showDialogFinish = false
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];
  @Watch('selectedActivity')
  change () {
    if (this.selectedActivity.status === 'done') {
      this.$store.commit('SteppersModule/SET_OPEN_MODAL', true)
      // this.showDialogFinish = true
    } else {
      this.$store.commit('SteppersModule/SET_OPEN_MODAL', false)
    }
  }

  private goToDocuments () {
    this.$router.push({ name: 'documents', })
  }

  private getSteppersList () {
    this.$emit('refreshActivity')
    this.$emit('getSteppersList')
  }

  public shouldShowStepName(element: any, stepper: string) {
    const isImplementador = this.role.includes('Implementador');
    const isCustomerSuccess = this.role.includes('Customer Success');
    const isCompany = this.role.includes('Company');

    const firstStepsConditions = ['start', 'preparation', 'corrections'];

    const implementadorConditions = firstStepsConditions;
    const customerSuccessConditions = firstStepsConditions;
    const companyConditions = ['approbation', 'final_document', 'comunication'];

    return (
      stepper !== element.name ||
      (isImplementador && implementadorConditions.includes(element.name)) ||
      (isCustomerSuccess && customerSuccessConditions.includes(element.name)) ||
      ((isImplementador || isCompany) && companyConditions.includes(element.name)) ||
      isCustomerSuccess
    );
  }
}
