
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters("ActivitiesModule", { selectedActivity: "getSelectedActivity" }),
  },
})
export default class ConfirmApproveDialog extends Vue {
  @Prop({ required: true }) readonly showDialog!: boolean;
  public selectedActivity: any;

  private justification = "";

  private dialog = false;

  @Watch("showDialog")
  show(val: boolean): void {
    if (val) {
      this.dialog = true;
    }
  }

  @Watch("dialog")
  dialogClose(): void {
    if (this.dialog === false) {
      this.$mixpanel.track("Cerrar - modal terminar actividad");
      this.$emit("close");
    }
  }

  private sendJustification() {
    this.dialog = false;
    this.$mixpanel.track("Actividad terminada", {
      Actividad: this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
    });
    this.$emit("action");
  }
}
