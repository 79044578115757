var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_vm.getSteppers),function(element,index){return _c('div',{key:index},[_c('div',{staticClass:"step-item"},[_c('div',{staticClass:"step-number relative"},[_c('div',{staticClass:"step",class:{
            'step-completed': element.is_completed || _vm.getStepperStay.name === element.name,
            'step-done': _vm.doneActivity,
            'activity-done': _vm.getStepperStay.step === 0,
          }},[(element.is_completed)?_c('img',{attrs:{"src":require("@/assets/icons/check.svg"),"alt":""}}):_c('span',[_vm._v(_vm._s(element.step))])])]),_c('div',{staticClass:"step-info relative"},[(_vm.shouldShowStepName(element, _vm.getStepperStay.name))?_c('div',{staticClass:"name-step"},[_vm._v(" "+_vm._s(_vm._f("stepsFilter")(element.name))+" ")]):_vm._e(),(
            _vm.getStepperStay.name === 'start' &&
            _vm.getStepperStay.name === element.name &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepOne',{on:{"refresh":_vm.getSteppersList}}):_vm._e(),(
            _vm.getStepperStay.name === 'preparation' &&
            _vm.getStepperStay.name === element.name &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepTwo',{on:{"refresh":_vm.getSteppersList}}):_vm._e(),(
            _vm.getStepperStay.name === 'review' &&
            _vm.getStepperStay.name === element.name &&
            _vm.role !== 'customer_success'
          )?_c('StepThree',{on:{"refresh":_vm.getSteppersList}}):_vm._e(),(
            _vm.getStepperStay.name === 'corrections' &&
            _vm.getStepperStay.name === element.name &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepFour',{on:{"refresh":_vm.getSteppersList}}):_vm._e(),(
            _vm.getStepperStay.name === 'approbation' &&
            _vm.getStepperStay.name === element.name &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepFive',{attrs:{"steperLenght":_vm.getSteppers.length},on:{"refresh":_vm.getSteppersList}}):_vm._e(),(
            _vm.getStepperStay.name === 'final_document' &&
            _vm.getStepperStay.name === element.name &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepSix',{attrs:{"steperLenght":_vm.getSteppers.length},on:{"refresh":_vm.getSteppersList}}):_vm._e(),(
            _vm.getStepperStay.name === 'comunication' &&
            _vm.getStepperStay.name === element.name &&
            !_vm.ADMIN_ROLES.some(adminRole => _vm.role.includes(adminRole))
          )?_c('StepSeven',{on:{"refresh":_vm.getSteppersList}}):_vm._e()],1)])])}),_c('FinishDialog',{attrs:{"showDialog":_vm.showDialogFinish},on:{"close":function($event){_vm.showDialogFinish = false},"action":_vm.goToDocuments}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }