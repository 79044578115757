
import { Vue, Component, Prop, } from 'vue-property-decorator'
import UploadFile from './UploadFile.vue'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'
import DoneActivityDialog from './DoneActivityDialog.vue'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    UploadFile,
    DoneActivityDialog,
  },
  computed: {
    ...mapGetters('SteppersModule', ['getDocumentsStepper',]),
    ...mapGetters('SteppersModule', { documentsStepper: 'getDocumentsStepper', }),
  },
})
export default class StepSix extends Vue {
  @Prop({ required: false, }) readonly steperLenght!: number
  private loading = false;
  private documentsStepper: any
  private date = ''
  private fileToUpload: FileUploadInterface[] = []
  private new_file = false
  private showDialogDone = false
  private showDialogFinish = false

  mounted () {
    this.getFilesDocuments()
  }

  private nextStep () {
    this.showDialogDone = true
  }

  private newFile (template: any) {
    this.new_file = template
  }

  private refreshDocuments (template: FileUploadInterface[]) {
    this.fileToUpload = template
  }

  private refreshAPI () {
    this.getFilesDocuments()
  }

  private sendData () {
    this.loading = true

    const data = {
      new_file: this.documentsStepper ? false : true,
      act_id: this.$route.params.task_id,
      datarooms_data: this.fileToUpload,
    }

    this.$store.dispatch('SteppersModule/saveComunication', data).then(() => {
      this.loading = false
      this.$store
        .dispatch('SteppersModule/finishedSummary', this.$route.params.task_id)
        .then(() => {
          this.$store.commit('SteppersModule/SET_OPEN_MODAL', true)
        })
      this.$emit('refresh')
    })
  }

  private requestBack () {
    this.loading = true
    this.$mixpanel.track('Volver atras - Step 7', {
      Actividad: this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
    })
    this.$store.dispatch('SteppersModule/requestBack', this.$route.params.task_id).then(() => {
      this.loading = false
      this.$emit('refresh')
    })
  }

  private getFilesDocuments () {
    this.$store.dispatch('SteppersModule/getFilesDocuments', this.$route.params.task_id)
  }
}
