
import { Vue, Component, Prop, } from 'vue-property-decorator'
import UploadFile from './UploadFile.vue'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'
import { formatISO, } from 'date-fns'
import { mapGetters, } from 'vuex'
import DoneActivityDialog from './DoneActivityDialog.vue'
import ConfirmReviewRequest from './ConfirmReviewRequest.vue'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    UploadFile,
    DoneActivityDialog,
    ConfirmReviewRequest,
  },
  computed: {
    ...mapGetters('SteppersModule', { documentsStepper: 'getDocumentsStepper', }),
    ...mapGetters('SteppersModule', ['getDocumentsStepper',]),
  },
})
export default class StepTwo extends Vue {
  private documentsStepper: any
  private getDateApprove: any
  private fileToUpload: FileUploadInterface[] = []
  @Prop({ required: false, }) readonly steperLenght!: number
  private new_file = false
  private date: null | string | number | Date = null
  private locale = { weekDays: ['L', 'M', 'M', 'J', 'V', 'S', 'D',], }
  private showDialogDone = false
  private reviewShowDialog = false
  private isDone = false
  private loading = false;

  mounted () {
    this.getFilesDocuments()
  }

  private refreshDocuments (template: FileUploadInterface[]) {
    this.fileToUpload = template
  }

  private refreshAPI () {
    this.getFilesDocuments()
  }

  get getButtonName () {
    if (this.steperLenght === 4) {
      this.isDone = true
      return 'Terminar actividad'
    } else {
      this.isDone = false
      return 'Continuar'
    }
  }

  private newFile (template: any) {
    this.new_file = template
  }

  private allowedDates (date: string | number | Date) {
    const allDates = new Date()
    const currentDates = new Date(date)
    return currentDates < allDates
  }

  private sendData () {
    this.loading = true

    let formatDate
    if (this.date) {
      formatDate = formatISO(new Date(this.date))
    } else {
      formatDate = formatISO(new Date())
    }

    const data = {
      new_file: this.documentsStepper ? false : true,
      act_id: this.$route.params.task_id,
      released_at: formatDate,
      datarooms_data: this.fileToUpload,
    }
    if (this.getButtonName == 'Continuar') {
      this.$mixpanel.track('Continuar - Step 5', {
        Actividad: this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
        'Fecha aprobacion': formatDate,
      })
    }
    this.$store.dispatch('SteppersModule/saveApprove', data).then(() => {
      if (this.isDone) {
        this.$store
          .dispatch('SteppersModule/finishedSummary', this.$route.params.task_id)
          .then(() => {
            this.$store.commit('SteppersModule/SET_OPEN_MODAL', true)
          })
      }

      this.loading = false
      this.$emit('refresh')
    })
  }

  nextStep () {
    if (!this.date) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Debes colocar una fecha de aprobacion',
        color_notification: 'danger',
      })
      return
    }

    if (this.steperLenght === 4) {
      this.showDialogDone = true
    } else {
      this.sendData()
    }
  }

  private requestReview () {
    this.reviewShowDialog = true
  }

  private refresh () {
    this.$emit('refresh')
  }

  private getFilesDocuments () {
    this.$store
      .dispatch('SteppersModule/getFilesDocuments', this.$route.params.task_id)
      .then((response) => {
        if (response.data.data) {
          this.date = response.data.data.approval_date
        }
      })
  }
}
