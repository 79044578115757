
import { Vue, Component, } from 'vue-property-decorator'
import ConfirmRejectDialog from './ConfirmRejectDialog.vue'
import ConfirmApproveDialog from './ConfirmApproveDialog.vue'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    ConfirmRejectDialog,
    ConfirmApproveDialog,OverlayBackground
  },
  computed: {
    ...mapGetters('BusinessModule', ['getSelectedProduct',]),
    ...mapGetters('SteppersModule', ['getStepperStay',]),
  },
})
export default class StepThree extends Vue {
  private role = this.$store.state.AuthModule.AuthState.role
  private showDialogReject = false
  private showDialogAppove = false
  private loading = false;
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];
  private requestReject (template: any) {
    this.loading = true

    const data: any = {
      description: template,
      act_id: this.$route.params.task_id,
    }
    this.$mixpanel.track('Necesita corecciones', {
      'Actividad': this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
      'Empresa': this.$store.state.BusinessModule.BusinessState.businessSelected.name,
      'Justificacion':template,})
    this.$store.dispatch('SteppersModule/rejectChanges', data).then(() => {
      this.loading = false
      this.$emit('refresh')
    })
  }

  private requestApprove () {
    this.loading = true
    this.$mixpanel.track('Listo para ser aprobado', {
      'Actividad': this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,
      'Empresa': this.$store.state.BusinessModule.BusinessState.businessSelected.name,})
    this.$store.dispatch('SteppersModule/approveChanges', this.$route.params.task_id).then(() => {
      this.loading = false
      this.$emit('refresh')
    })
  }
}
