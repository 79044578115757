
import { Vue, Component, } from 'vue-property-decorator'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {OverlayBackground},
  computed: {},
})
export default class StepOne extends Vue {
  private loading = false;

  private startActivity () {
    this.loading = true
    this.$mixpanel.track('Comenzar Actividad', {'Actividad': this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.name,})
    this.$store.dispatch('SteppersModule/startActivity', this.$route.params.task_id).then(() => {
      this.loading = false
      this.$emit('refresh')
    })
  }
}
